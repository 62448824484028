import React from 'react';
import { FaBuffer,FaUser,FaRegNoteSticky } from "react-icons/fa6";
import imgCat1 from './../img/cat-1.jpg';
import imgCat2 from './../img/cat-2.jpg';
import imgCat3 from './../img/cat-3.jpg';
import imgCat4 from './../img/cat-1.jpg';
import imgCat11 from './../img/cat-11.jpg';
import imgCat22 from './../img/cat-22.jpg';
import imgCat33 from './../img/cat-33.jpg';
import imgCat44 from './../img/cat-44.jpg';
import imgCat111 from './../img/cat-111.jpg';
import imgCat222 from './../img/cat-222.jpg';
import imgCat333 from './../img/cat-333.jpg';
import imgCat444 from './../img/cat-444.jpg';
import imgCat1111 from './../img/cat-1111.jpg';
import imgCat2222 from './../img/cat-2222.jpg';
import imgCat3333 from './../img/cat-3333.jpg';
import imgCat4444 from './../img/cat-4444.jpg';
const KeyFeature = () => {

  return (
<>
<section>
<div className="container-xxl py-5 category">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">KEY FEATURES</h6>
                <h1 className="mb-5">GROWTH MINDSET GRADING</h1>
            </div>
            <div className="row g-3">
                <div className="col-lg-7 col-md-6">
                    <div className="row g-3">
                        <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat1} alt="imgCat1" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">ORGANIZATIONAL INFORMATION</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat2} alt="imgCat1" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">USER INFORMATION</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat3} alt="imgCat1" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">ATTENDANCE</h5>
                                    
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight:1}}>
                    <a className="position-relative d-block h-100 overflow-hidden" href="">
                        <img className="img-fluid position-absolute w-100 h-100 absec" src={imgCat4} alt="imgCat1"  />
                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                            <h5 className="m-0">GRADEBOOK / REPORT CARDS / TRANSCRIPTS</h5>
                        </div>
                    </a>
                </div>
            </div>
            <div className="row g-3 mt-4">
                <div className="col-lg-7 col-md-6">
                    <div className="row g-3">
                        <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat11} alt="imgCat11" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">COLLABORATIVE LESSON PLANNER</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat22} alt="22" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">MASTERY BUILDER</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat33} alt="img33" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">COLLABORATIVE LEARNING</h5>
                                   
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight: '350px'}}>
                    <a className="position-relative d-block h-100 overflow-hidden" href="">
                        <img className="img-fluid position-absolute w-100 h-100 absec" src={imgCat44} alt="img44" />
                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                            <h5 className="m-0">FIND / SHARE YOUR "BEST PRACTICES"</h5>
                        </div>
                    </a>
                </div>
            </div>
            <div className="row g-3 mt-4">
                <div className="col-lg-7 col-md-6">
                    <div className="row g-3">
                        <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat111} alt="img111" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">CONTROL OF CONTENT</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat222} alt="img222" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">PURPOSEFUL MONITORING SYSTEMS</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat333} alt="img333" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">FORMATIVE ASSESSMENT TOOLS</h5>
                                    
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight: '350px'}}>
                    <a className="position-relative d-block h-100 overflow-hidden" href="">
                        <img className="img-fluid position-absolute w-100 h-100 absec" src={imgCat444} alt="img444" />
                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                            <h5 className="m-0">SUMMARY DRILL DOWN DASHBOARD</h5>
                        </div>
                    </a>
                </div>
            </div>
            <div className="row g-3 mt-4">
                <div className="col-lg-7 col-md-6">
                    <div className="row g-3">
                        <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat1111} alt="img1111" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">MONITOR WHAT YOU WANT</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat2222} alt="img2222" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">SYSTEMIZE SUGGESTIONS</h5>
                                  </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={imgCat3333} alt="img3333" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                                    <h5 className="m-0">NOTIFICATIONS</h5>
                                    
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight: '350px'}}>
                    <a className="position-relative d-block h-100 overflow-hidden" href="">
                        <img className="img-fluid position-absolute w-100 h-100 absec" src={imgCat4444} alt="img4444" />
                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin:'1px'}}>
                            <h5 className="m-0">FIND / SHARE YOUR TALENT</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </section>
</>
  );
}

export default KeyFeature;