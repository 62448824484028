import React from 'react';
import Footer from './Footer';
const Term = () => {

  return (
<>
<div className="container-xxl py-5">
    <div className="container">
        <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2>Term and Conditions</h2>
                <ol>
                    <li><strong>Introduction</strong></li>
                </ol>
                <p>The On Standard Terms and Conditions written on this webpage shall manage your use of On. These Terms
                    will be applied fully and affect to your use of On. By using On, you agreed to accept all terms and
                    conditions written in here. You must not use On if you disagree with any of the On Standard Terms
                    and Conditions.</p>
                <p>Minors or people below 7 years old are not allowed to use On.</p>
                <ol start="2">
                    <li><strong>Intellectual Property Rights</strong></li>
                </ol>
                <p>Other than the content you own, under these Terms, On and/or its licensors own all the intellectual
                    property rights and materials contained in On.</p>
                <p>You are granted limited license only for purposes of viewing the material contained on On.</p>
                <ol start="3">
                    <li><strong>Restrictions</strong></li>
                </ol>
                <p>You are specifically restricted from all of the following</p>
                <ul>
                    <li>publishing any On material in any other media;</li>
                    <li>selling, sublicensing and/or otherwise commercializing any On material;</li>
                    <li>publicly performing and/or showing any On material;</li>
                    <li>using On in any way that is or may be damaging to On;</li>
                    <li>using On in any way that impacts user access to On;</li>
                    <li>using On contrary to applicable laws and regulations, or in any way may cause harm to On, or to
                        any person or business entity;</li>
                    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in
                        relation to On;</li>
                    <li>using On to engage in any advertising or marketing.</li>
                </ul>
                <p>Certain areas of On are restricted from being access by you and On may further restrict access by you
                    to any areas of On, at any time, in absolute discretion. Any user ID and password you may have for
                    On are confidential and you must maintain confidentiality as well.</p>
                <ol start="4">
                    <li><strong>Your Content</strong></li>
                </ol>
                <p>In these On Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or
                    other material you choose to display on On. By displaying Your Content, you grant On a
                    non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish,
                    translate and distribute it in any and all media.</p>
                <p>Your Content must be your own and must not be invading any third-party’s rights. On reserves the
                    right to remove any of Your Content from On at any time without notice.</p>
                <ol start="5">
                    <li><strong>No warranties</strong></li>
                </ol>
                <p>On is provided “as is,” with all faults, and On express no representations or warranties, of any kind
                    related to On or the materials contained on On. Also, nothing contained on On shall be interpreted
                    as advising you.</p>
                <ol start="6">
                    <li><strong>Limitation of liability</strong></li>
                </ol>
                <p>In no event shall On, nor any of its officers, directors and employees, shall be held liable for
                    anything arising out of or in any way connected with your use of On whether such liability is under
                    contract. &nbsp;On, including its officers, directors and employees shall not be held liable for any
                    indirect, consequential or special liability arising out of or in any way related to your use of On.
                </p>
                <ol start="7">
                    <li><strong>Indemnification</strong></li>
                </ol>
                <p>You hereby indemnify to the fullest extent On from and against any and/or all liabilities, costs,
                    demands, causes of action, damages and expenses arising in any way related to your breach of any of
                    the provisions of these Terms.</p>
                <ol start="8">
                    <li><strong>Severability</strong></li>
                </ol>
                <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions
                    shall be deleted without affecting the remaining provisions herein.</p>
                <ol start="9">
                    <li><strong>Variation of Terms</strong></li>
                </ol>
                <p>On is permitted to revise these Terms at any time as it sees fit, and by using On you are expected to
                    review these Terms on a regular basis.</p>
                <ol start="10">
                    <li><strong>Assignment</strong></li>
                </ol>
                <p>On is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms
                    without any notification. However, you are not allowed to assign, transfer, or subcontract any of
                    your rights and/or obligations under these Terms.</p>
                <ol start="11">
                    <li><strong>Entire Agreement</strong></li>
                </ol>
                <p>These Terms constitute the entire agreement between On and you in relation to your use of On, and
                    supersede all prior agreements and understandings.</p>
                <ol start="12">
                    <li><strong>Governing Law &amp; Jurisdiction</strong></li>
                </ol>
                <p>These Terms will be governed by and interpreted in accordance with the laws of Hong Kong, and you
                    submit to the non-exclusive jurisdiction of the state and federal courts located in Hong Kong for
                    the resolution of any disputes.</p>
                
            </div>
        </div>
    </div>
</div>
<Footer />
</>
  );
}

export default Term;