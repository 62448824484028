import React from 'react';
import { NavLink } from 'react-router-dom';
import slide1 from './../img/slide1.jpg';
import slide2 from './../img/slide2.jpg';
import Slider from "react-slick";
const Slide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay:true,
        slidesToScroll: 1
      };
  return (
<>
<Slider {...settings}>
{/* Slide 1 */}
    <div>
    <div className="owl-carousel-item position-relative" style={{height:'600px'}} >
                <img className="img-fluid w-100" src={slide1} alt="slide1" />
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{background: "rgba(24, 29, 56, .7)"}}>
                    <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-sm-10 col-lg-8">
                                <h5 className="text-primary text-uppercase mb-3 animated slideInDown">IGNITE THE GENIUS IN EVERYONE</h5>
                                <h1 className=" text-white animated slideInDown">Discover a new world of learning. A world of possibilities</h1>
                                <p className="fs-5 text-white mb-4 pb-2">Empower your students' future with online education. Empowering your student to learn and grow.</p>
                                <NavLink className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft" to={"/signup"}>Join Now</NavLink>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    {/* Slide 2 */}
    
    <div>
    <div className="owl-carousel-item position-relative" style={{height:"600px"}}>
                <img className="img-fluid w-100" src={slide2} alt="slide2" />
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{background: "rgba(24, 29, 56, .7)"}}>
                    <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-sm-10 col-lg-8">
                                <h5 className="text-primary text-uppercase mb-3 animated slideInDown">Maverick learning for a modern world.</h5>
                                <h1 className=" text-white animated slideInDown">Online education, limitless opportunities.</h1>
                                <p className="fs-5 text-white mb-4 pb-2">Advance your student’s skills and career with online courses. Self Accommodates Are What We Do</p>
                                <NavLink className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft" to={"/signup"}>Join Now</NavLink>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</Slider>

</>
  );
}

export default Slide;