import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import emal1 from './../img/email1.png'
import { useNavigate } from 'react-router-dom';
import { FaMap,FaPhone,FaEnvelope } from "react-icons/fa6";
import CookieConsent from "react-cookie-consent";
import MailchimpSubscribe from "react-mailchimp-subscribe"


const Footer = () => {
const url = "https://gmail.us14.list-manage.com/subscribe/post?u=0a34faf5033c71da665877a3a&amp;id=83f32d9172&amp;f_id=002641e0f0";
const SimpleForm = () => <MailchimpSubscribe url={url}/>
const navigate = useNavigate();
const [email, setEmail] = useState("");
const [currentDate, setCurrentDate] = useState(new Date());
const baseUrl = process.env.REACT_APP_BaseUrl;
function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
const sendEmail = async () => {
  let dataSend = {
    email: email,
    dt: formatDate(currentDate),
  };

  const res = await fetch(`${baseUrl}/email/sendNewsEmail`, {
    method: "POST",
    body: JSON.stringify(dataSend),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    // HANDLING ERRORS
    .then((res) => {
      console.log(res);
      if (res.status > 199 && res.status < 300) {
        navigate('/thank-you');
      }
    });
};
  return (
<>
<div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-white mb-3">Quick Link</h4>
                    <a className="btn btn-link scrollto textdecodnone" href="/#about">About Us</a>
                    <a className="btn btn-link scrollto textdecodnone" href="/#contact">Contact Us</a>
                    <Link className='btn btn-link textdecodnone' to={"/private-policy"}>Privacy Policy</Link>
                    <Link className='btn btn-link textdecodnone' to={"/term-conditions"}>Terms & Condition</Link>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-white mb-3">Contact</h4>
                    <p className="mb-2"><FaMap className="text-white me-3" />Ratan Park, New Delhi - 110015</p>
                    <p className="mb-2"><FaPhone className="text-white me-3" /> +91 9312 831 662</p>
                    <p className="mb-2"><FaEnvelope className="text-white me-3" /> <img src={emal1} className="emlimg" /></p>
                   
                </div>
                
                <div className="col-lg-4 col-md-6 mailchimp">
                    <h4 className="text-white mb-3">Newsletter</h4>
                    <p>Subscribe to our mailing list for latest offers and news.</p>
                    {/* <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Your email" required />
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2" onClick={() => sendEmail()}>SignUp</button>
                    </div> */}
                    <MailchimpSubscribe
                      url={url}
                      render={({ subscribe, status, message }) => (
                        <div>
                          <SimpleForm onSubmitted={formData => subscribe(formData)} />
                          {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                          {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                        </div>
                      )}
                    />
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-10 text-center text-md-start mb-3 mb-md-0">
                        &copy; Maverick Minds, All Right Reserved.<a className="border-bottom textdecodnone" href="https://www.tis-ites.com/services" target="blank"> Creative Websites</a> by <a className="border-bottom textdecodnone" href="https://www.tis-ites.com" target="blank">Target Internet Services</a><br /><br />
                       
                    </div>
                    <div className="col-md-2 text-center text-md-end">
                        <div className="footer-menu">
                             <Link to={"/"}>Home</Link>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CookieConsent
  location="bottom"
  buttonText="Accept"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B",width:'30%',left:'auto',right:0 }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  enableDeclineButton
  onDecline={() => {
    alert("Declined!");
  }}
  expires={150}
>
  We uses cookies to give you the best online experience. By agreeing you accept the use of coockies in accordance with our coockie policy
  
</CookieConsent>
</>
  );
}

export default Footer;