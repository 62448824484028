import React from 'react';
import About from './About';
import Feature from './Feature';
import KeyFeature from './KeyFeature';
import AdvanceFeature from './AdvanceFeature';
import Member from './Member';
import ContactForm from './ContactForm'
import Footer from './Footer';
import Slide from './Slide';
const Home = () => {
return (
    <>
    <Slide />
    <About />
    <Feature />
    <KeyFeature />
    <AdvanceFeature />
    <Member />
    <section id="contact" class="contact">
    <ContactForm />
    </section>
    <Footer />

 </>
  );
}

export default Home;