import React from 'react';
import { FaBuffer,FaUser,FaRegNoteSticky } from "react-icons/fa6";
const Feature = () => {

  return (
<>
<section id="feature" className="feature_part feature">
        <div className="container">
		<div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">Features</h6>
                <h1 className="mb-5">Our Features</h1>
            </div>
            <div className="row">
                <div className="col-sm-6 col-xl-3 align-self-center">
                    <div className="single_feature_text ">
                        <h2>Awesome <br /> Feature</h2>
                        <p>Maverick Minds is an infrastructure for delivery of educational contents to the students. We are highlighting some of the features that our system inherits from our traditional schooling system.</p><br /><br />
                       
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <FaBuffer className='fa fa-3x  text-primary mb-4' />
                            <h5 className="mb-3">Design To Promote Best Practices</h5>
                            <p>A vertical drill down system that always counts the best things. It is well categorized with Classroom activities, Assignments, Lessons, Units, Courses and other stuff of the schools.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                        <FaUser className='fa fa-3x  text-primary mb-4' />
                            <h5 className="mb-3">Collaboration / Accountability</h5>
                            <p>Our system is goal oriented and distributed among the members of school. A transparent process and seamless communication allow members to define and achieve the Goal.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                        <FaRegNoteSticky className='fa fa-3x  text-primary mb-4' />
                            <h5 className="mb-3">User Experience</h5>
                            <p>It is a feeling. Focused on understanding of user behavior. Easy to navigate, self driven contents flow, Vertical Drill Down to analyze and gather the data, interactive yet informative for all the members.</p>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    </section>
</>
  );
}

export default Feature;