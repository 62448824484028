import React from 'react';
import aboutImg from './../img/about.jpg'
import { FaRightLong } from "react-icons/fa6";
const About = () => {

  return (
<>
<section id="about" className="about">
    <div className="container-xxl">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: '400px'}}>
                    <div className="position-relative h-100">
                        <img className="img-fluid position-absolute w-100 h-100 absec" src={aboutImg} alt="" />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
                    <h1 className="mb-4">MAVERICK MINDS</h1>
                    <p className="mb-4">Right to equality and Right to freedom for every students that is what our application is.</p>
                    <p className="mb-4">Our understanding is that A school is performing well if 80% of total jobs are performed between Teachers and Students. Means spending time in shaping the future of Students.</p>
                    <p className="mb-4">This is what Maverick Mind is doing. Our focus is to engage Teachers and Students in a way that is interesting and equally distributed. Maverick Mind is an infrastructure of online schooling system, where services are at top priority.</p>
                    
                    <p className="mb-4">Authority/responsibility/liability of a Teacher is equally distributed and it is managed in a way that all the members of the School fills a centralized environment.</p>
                    <p className="mb-4">Our Schooling system caters to the following type of users/members.</p>
                    
                    
                    <div className="row gy-2 gx-4 mb-4">
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Student</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Parent</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Teacher</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Principal</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> CEO</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Regional Head</p>
                        </div>
                         <div className="col-sm-6">
                            <p className="mb-0"><FaRightLong /> Country Head</p>
                        </div>
                    </div>
                    <p className="mb-4">Innovative learning for a modern world. Maverick Learning made simple and accessible.</p>
                   
                </div>
            </div>
        </div>
    </div>
</section>
</>
  );
}

export default About;