import './css/Custom.css';
import './css/Crousel.css';
import './css/Animated.css';
import './css/Bootstrap.css';
import './css/Style.css';
import AuthUser from './pages/AuthUser';
import Guest from './pages/navbar/Guest';
import Auth from './pages/navbar/Auth';

function App() {

    const {getToken} = AuthUser();
    if(!getToken()){
      return <Guest />
    }
    return (
        <Auth />
    );
  
}

export default App;
