import React from 'react';
import teacher from './../img/teacher.jpg'
import principal from './../img/principal.jpg'
import ceo from './../img/ceo.jpg'
import regional from './../img/regional.jpg'
import head from './../img/head.jpg'

const Member = () => {

  return (
<>
<div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="section-title bg-white text-center text-primary px-3">Organizational Members</h6>
                <h1 class="mb-5">Maverick Mind has tremendous benefits for everyone</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item bg-light">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={teacher} alt="teacher" />
                        </div>
                        <div class="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                           
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0 mt-3">Teacher</h5>
                            <small>“Teaching with data is
key to my student’s success, this Obasi GPS is a huge time saver for me”</small>
                        </div>
                    </div>
                </div>
				<div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item bg-light">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={principal} alt="principal" />
                        </div>
                        <div class="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0 mt-3">Principal</h5>
                            <small>“As principal, I can easily track each one of my classrooms and we as a group can make adjustments quickly and easily.”</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item bg-light">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={ceo} alt="ceo" />
                        </div>
                        <div class="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0 mt-3">CEO</h5>
                            <small>“As CEO, I can finally monitor all my schools daily progress and then quickly determine how we can best support them.”</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item bg-light">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={regional} alt="reg" />
                        </div>
                        <div class="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0 mt-3">Regional Head</h5>
                            <small>I am happy to see that the last student in the queue is also getting the same standard of education. Equally Distributed.</small>
                        </div>
                    </div>
                </div>
                </div>
                <div class="row d-flex justify-content-center mt-3">
                 <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item bg-light">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={head} alt="" />
                        </div>
                        <div class="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0 mt-3">Country Head</h5>
                            <small>Right to equality and Right to freedom - this is what I can see our application is doing.</small>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</>
  );
}

export default Member;