import React from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { FaMap,FaPhone,FaEnvelope } from "react-icons/fa6";
import emailImg from './../img/email.png'
const ContactForm = () => {
const { REACT_APP_RcSiteKey } = process.env;
const [btnload, setBtnload] = useState(false);
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [phone, setPhone] = useState("");
const [message, setMessage] = useState("");
const [recaptchaValue, setRecaptchaValue] = useState('');
const captchaRef = useRef()
const navigate = useNavigate();
const [currentDate, setCurrentDate] = useState(new Date());
function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
 const baseUrl = process.env.REACT_APP_BaseUrl;
// const baseUrl = "https://www.serve.maverick-minds.com";

const sendEmail = async () => {
    
   if(recaptchaValue==''){
       alert('ReCaptcha Should not be empty');
       return false;
   }
   setBtnload(true)
  let dataSend = {
    name: name,
    email: email,
    phone: phone,
    message: message,
    dt: formatDate(currentDate),
  };

  const res = await fetch(`${baseUrl}/email/sendEmail`, {
    method: "POST",
    body: JSON.stringify(dataSend),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    // HANDLING ERRORS
    .then((res) => {
      console.log(res);
      setBtnload(false)
      if (res.status > 199 && res.status < 300) {
        navigate('/thank-you');
      }
    });
};
const onChange = value => {
    setRecaptchaValue(value);
  }
  return (
<>
<div className="container-xxl" id='contactForm'>
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">Contact Us</h6>
                <h1 className="mb-5">Contact For Any Query</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h5>Get In Touch</h5>
                    <p className="mb-4">We have always welcomed walk-in visitors with appointments.<br />To receive a quotation from us or discuss your project, please fill in the following form, and we will contact you.</p>
                    <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary condiv">
                            <FaMap className="text-white" />
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Office</h5>
                            <p className="mb-0">Ratan Park, New Delhi - 110015</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary condiv">
                        <FaPhone className="text-white" />
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Mobile</h5>
                            <p className="mb-0">+91 9312 831 662</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary condiv" >
                        <FaEnvelope className="text-white" />
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Email</h5>
                            <p className="mb-0"><img src={emailImg} className="emlimg" /></p>
                        </div>
                    </div>
                </div>
           
                <div className="col-lg-8 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control"  placeholder="Your Name" 
                                    onChange={(e) => setName(e.target.value)} required />
                                    <label for="name">Your Name</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control" 
                                    onChange={(e) => setEmail(e.target.value)} placeholder="Your Email" required />
                                    <label for="email">Your Email</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <input type="number" className="form-control" 
                                    onChange={(e) => setPhone(e.target.value)} placeholder="Phone" required />
                                    <label for="subject">Phone</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control" 
                                    onChange={(e) => setMessage(e.target.value)}
                                     placeholder="Leave a message here"  rows={5} cols={52} style={{height: '150px'}} required></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            
                            <div className="col-6">
                            <ReCAPTCHA
                                    sitekey={REACT_APP_RcSiteKey}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6 text-right">
                               
                                {
                                btnload?
                                <button class="btn btn-primary" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm me-2 cs-btn" role="status" aria-hidden="true"></span>
                                    Loading...
                                    </button>
                                :  <button className="btn btn-primary  py-3" type="submit" onClick={() => sendEmail()}>Send Message</button>
                                }
                            </div>
                        </div>
                   
                </div>
            </div>
        </div>
    </div>
</>
  );
}

export default ContactForm;