import React from 'react';
import {useEffect,useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Routes, Route,NavLink,useLocation} from 'react-router-dom';
import Error from '../Error';
import Home from '../Home';
import Term from '../Term';
import Policy from '../Policy';
import Coockies from '../Coockies';
import Signup from '../Signup';
import Thank from '../Thank';
import logo from './../../img/logom.png'

export default function Guest() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.hash)
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
    if(location.hash==='#about'){
      const aboutEl = document.getElementById('about');
      if (aboutEl) {
        aboutEl.scrollIntoView({ behavior: 'smooth' });
      }
    }else if(location.hash==='#feature'){
      const featureEl = document.getElementById('feature');
      if (featureEl) {
        featureEl.scrollIntoView({ behavior: 'smooth' });
      }
    }else if(location.hash==='#contact'){
      const contactEl = document.getElementById('contactForm');
      if (contactEl) {
        contactEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
  }, [location]);
  const [homeLoc, setHomeLoc] = useState(true);
  console.log(homeLoc)
  
//   useEffect(() => {
//     navigateFun(0)
// }, []);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bg-white shadow-sm p-0" sticky="top">
        <div class="container-fluid">
          <Navbar.Brand><Link to={"/"}>
            <img src={logo} onClick={() => setHomeLoc(true)} />
          </Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-3">
            {
                homeLoc?
                    <div className='d-menu'>
                    <NavLink className="nav-link" activeClassName="active" to={"/"}>HOME</NavLink>              
                     <a href='#about' className="nav-link">ABOUT US</a>           
                    <a href='#feature' className="nav-link">FEATURS </a>           
                    <a href='#contact' className="nav-link">CONTACT US</a> 
                    <NavLink className="nav-link" activeClassName="active" to={"/signup"} onClick={() => setHomeLoc(false)} >JOIN NOW</NavLink>
                    </div>
                    :
                    <div className='d-menu'>
                    <NavLink className="nav-link" activeClassName="active" to={"/"} onClick={() => setHomeLoc(true)}>HOME</NavLink>              
                    <a href={process.env.REACT_APP_URL+'/#about'} className="nav-link">ABOUT US</a>           
                    <a href={process.env.REACT_APP_URL+'/#feature'} className="nav-link">FEATURS</a>           
                    <a href={process.env.REACT_APP_URL+'/#contact'} className="nav-link">CONTACT US</a>           
                    <NavLink className="nav-link" activeClassName="active" to={"/signup"} >JOIN NOW</NavLink>
                    </div>
                      
                  }
                       
                           
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home/{section}" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/term-conditions" element={<Term />} />
        <Route path="/private-policy" element={<Policy />} />
        <Route path="/coockies" element={<Coockies />} />
        <Route path="/thank-you" element={<Thank />} />
        <Route path="*" element={<Error />} />
      </Routes>


    </div>
  )
}
