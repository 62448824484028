import React from 'react';
import { Link} from 'react-router-dom';
import Footer from './Footer';
import thankImg from './../img/thanks.jpg'
const Thank = () => {

  return (
<>
<div className="container-xxl py-5">
    <div className="container">
    <div className="row box-shadow mt-5 mb-5 pb-5">
                        <div className="col-xs-12 col-sm-3 col-md-3">
                            <img className="lib-img-show w-100 mb-4" src={thankImg} alt="thanks" />
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-9 align-self-center">
                            <h3 className="lib-row lib-header">
                                Thank You for your interest.<br /> We shall get back to you soon!
                                <div className="lib-header-seperator"></div><br />
                                <Link to={"/"} > <button className='btn btn-primary'>Back to home</button></Link>
                            </h3>
                            
                        </div>
                    </div>
    </div>
</div>
<Footer />
</>
  );
}

export default Thank;