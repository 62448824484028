import React from 'react';
import Footer from './Footer';
const Coockies = () => {

  return (
<>
<div className="container-xxl py-5">
    <div className="container">
        <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <h2>Cookies</h2>
                <div id='cookie-bar'>
                    <div id='contentcookie'>
                        <p>We use cookies and similar technologies to recognize your repeat visits and preferences, to
                            measure the effectiveness of campaigns, and improve our websites. For settings and more
                            information about cookies, view our <a href='#'>Cookie Policy</a>. By clicking “I accept” on
                            this banner or using our site, you consent to the use of cookies.</p>
                        <div class='clear'></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<Footer />
</>
  );
}

export default Coockies;