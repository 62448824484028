import React from 'react'

export default function Error() {
  return (
    <>
      <center>
        <div className='mt-5'>
          <h1>404 Not Found !</h1>
        </div>
        <div className="contant_box_404">
         

          <p>the page you are looking for not avaible!</p>

          <a className="link_404">Go to Home</a>
        </div>
      </center>
    </>

  )
}
