import React from 'react';
import { FaPencil,FaUser } from "react-icons/fa6";
import AdvanceFImg from './../img/advance_feature_img.png'
const AdvanceFeature = () => {

  return (
<>
<section className="advance_feature learning_part" style={{backgroundColor:'#f0fbfc'}}>
        <div className="container">
            <div className="row align-items-sm-center align-items-xl-stretch">
                <div className="col-md-7 col-lg-7">
                    <div className="learning_member_text">
                        <h5>Advance feature</h5>
                        <h2>Our Advance Educator
                            Learning System</h2>
                        <p>The Supreme of the Education system is <b>"THE TEACHER"</b>. You are the best and you have the best online system. A door of possibility and a platform to show your best.</p>
                        <div className="row mt-4">
                            <div className="col-sm-6 col-md-12 col-lg-6 mb-2">
                                <div className="learning_member_text_iner adlear">
                                  <span><FaPencil  /></span>
                                    <h4>Progress Monitoring</h4>
                                    <p>It is an ongoing process to monitor all the activities. Set your target and see how you or your team perform. Readjust or reset your target on a click and continue monitoring. <br /></p><br />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-12 col-lg-6 mb-2">
                                <div className="learning_member_text_iner adlear">
                                <span><FaUser /></span>
                                
                                    <h4>Recommendations</h4>
                                    <p>It comes in two ways. Set your target and get the recommendation to achieve the target. Get recommendations from the best practices. Both are data driven and adjustable as per your requirement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5">
                    <div className="a mt-5">
                        <img src={AdvanceFImg} className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</>
  );
}

export default AdvanceFeature;